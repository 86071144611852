@keyframes shimmer {
    to {
        background-position-x: 0%
    }
}

.root {
    padding: 4% 4%;

    @media screen and (max-width:480px) {
        padding: 12% 4%;
    }
}

.imageParent {
    float: var(--float);
    width: 40%;
    aspect-ratio: 1;
    margin: var(--margin);
    height: min-content;
    border-radius: 24px;
    overflow: hidden;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;

    @media screen and (max-width:1024px) {
        width: 50%;
    }

    @media screen and (max-width:480px) {
        width: 100%;
        margin-bottom: 24px;
    }
}

.image {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
}

.textParent {
    font-size: calc(34px + (38 - 34) * ((100vw - 380px) / (1200 - 380)));
    text-align: justify;

    @media screen and (max-width:480px) {
        text-align: flex-start;
        margin-left: 4px;
    }
}

.title {
    font-size: 1em;
    margin-bottom: 12px;
    color: #353441;
    font-family: 'Raleway';
    font-weight: 600;
}

.description {
    font-size: 0.5em;
    line-height: 180%;
    margin-bottom: 24px;
    color: #353441;
    white-space: pre-line;
}