.parent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 8% 2%;

    @media screen and (max-width: 480px) {
        padding: 16% 2% 8% 2%;

    }
}

.container {
    display: flex;
    color: #353441;
    align-items: flex-start;
    align-self: center;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.aboutImageContainer {
    flex: 0.7;
    border-radius: 28px;
    overflow: hidden;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
    to {
        background-position-x: 0%
    }
}

.aboutImage {
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;

}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding: 0 24px;
    margin-left: 4%;
    font-size: calc(32px + (36 - 32) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width:480px) {
        padding: 0 20px;
        margin-left: 0%;
        margin-top: 12%;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 100%;
    font-weight: 500;
    margin-bottom: 24px;
}

.title {
    color: #353441;
    font-size: 0.95em;
    font-weight: 600;
    margin-bottom: 2px;
    margin-left: 2px;
}

.title2 {
    color: #F3BC4C;
    font-size: 1em;
    font-weight: 600;
}

.description {
    color: #353441;
    font-size: 0.55em;
    margin-bottom: 32px;
    line-height: 180%;

    @media screen and (max-width:480px) {
        margin-bottom: 16px;
    }
}

.descriptionMoto {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 240%;
}

.aboutUsButton {
    text-decoration: none;
    background-color: #E96035;
    border-radius: 20px 6px 20px 6px;
    width: max-content;
    font-size: 0.55em;
    display: flex;
    align-items: center;
    padding: 14px 28px;
    color: #FFFFFF;
    box-shadow: 0 0 24px #E9603564;

    @media screen and (max-width:480px) {
        padding: 12px 18px;
    }
}

.aboutUsButtonIcon {
    background-color: #FFFFFF;
    color: #E96035;
    border-radius: 1000px;
    margin-left: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    aspect-ratio: 1;
    transition: 0.5s ease;
}

.aboutUsButton:hover {
    .aboutUsButtonIcon {
        transform: rotate(-45deg);
        transition: 0.5s ease
    }
}