.parent {
    padding: 0 4%;
    
    @media screen and (max-width: 480px) {
        padding: 0 2%;

    }
}

.container {
    display: flex;
    flex-direction: column;
}

.productGridTitle {
    font-size: calc(40px + (44 - 40) * ((100vw - 380px) / (1200 - 380)));
    margin-bottom: 24px;
    color: #353441;
    font-family: 'Raleway';
    font-weight: 600;
}

.productGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    column-gap: 24px;
    row-gap: 24px;

    @media screen and (max-width: 480px) {
        column-gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }
}


.productFilterCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
    column-gap: 12px;
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none
}

.filterChip {
    border: var(--product-pill-border) 1px solid;
    background-color: var(--product-pill-background);
    border-radius: 100px;
    padding: 6px 16px;
    white-space: nowrap;
    font-size: calc(14px + (16 - 14) * ((100vw - 380px) / (1200 - 380)));
    color: var(--product-pill-color);
    cursor: pointer;
}

.productCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FDFBF8;
    box-shadow: 0 0 16px #00000012;
    border-radius: 12px;
    padding: 12px 24px;
    height: 100%;
    font-size: calc(16px + (20 - 16) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 480px) {
        padding: 12px 0;
    }
}

.productImage {
    width: 56%;
    flex: 1;
    aspect-ratio: 0.95;
    object-fit: fill;

    @media screen and (max-width: 840px) {
        width: 80%;
    }

    @media screen and (max-width: 480px) {
        width: 80%;
    }
}

.productContent{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
}

.productTitle {
    font-size: 1em;
    margin-top: 12px;
    font-weight: 500;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    font-weight: 700;
}

.productDescription {
    font-size: 0.65em;
    margin-bottom: 16px;
    color: #292929;
    text-align: center;
}

.productInfo {
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
    justify-content: center;
    padding: 4px 16px;
}