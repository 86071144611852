.parent {
    padding: 2dvb 8%;

    @media screen and (max-width:480px) {
        padding: 8% 4%;
    }
}

.productHeading {
    font-size: calc(28px + (32 - 28) * ((100vw - 380px) / (1200 - 380)));
    margin-bottom: 6%;
    font-weight: 600;
    color: #353441;
    font-family: 'Raleway';

}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productContainer {
    position: relative;
    width: 100%;
    height: min-content;
}

.productShelf {
    width: 100%;
    height: 56%;
    position: absolute;
    z-index: 0;
    background-color: #558E68;
    bottom: 0;
    border-radius: 24px;
    box-shadow: 0 4px 4px #00000048;

    @media screen and (max-width:786px) {
        height: 80%;
    }
}

.productRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    width: 100%;
    z-index: 2;

    @media screen and (max-width:786px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 32px;
        padding-top: 24px;
    }

    @media screen and (max-width:540px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 24px;
    }
}

.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    height: 100%;
    z-index: 2;
    font-size: calc(17px + (22 - 17) * ((100vw - 380px) / (1200 - 380)));
}

.productImage {
    width: 80%;
    flex: 1;
    aspect-ratio: 1;
    object-fit: fill;
    transition: all .4s ease;
}

.productImage:hover {
    transform: scale(1.05);
}

.productTitle {
    font-size: 0.9em;
    font-weight: 500;
    text-align: center;
    padding: 0 8px;
    margin-top: 12px;
    margin-bottom: 4px;
}

.productSubtitle {
    font-size: 0.7em;
    margin-bottom: 24px;
}

.productKnowMore {
    background-color: #F3BC4C;
    width: max-content;
    border-radius: 0 0 20px 20px;
    padding: 6px 40px;
    position: absolute;
    left: 0;
    text-decoration: none;
    cursor: pointer;
    right: 0;
    margin: 0 auto;
    color: #FFFFFF;
    font-size: clamp(0.2rem, 3vw + 0.2rem, 1.1rem);
    text-align: center;
    width: max-content;
    font-weight: 600;
    box-shadow: 0 4px 4px #00000024;
    transition: all .4s ease;

    @media screen and (max-width:540px) {
        padding: 4px;
        width: 50%;

    }
}

.productKnowMore:hover {
    animation: ease;
    padding-top: 16px;
}