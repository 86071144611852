.text {
    color: transparent;
    font-size: calc(72px + (120 - 72) * ((100vw - 380px) / (1200 - 380)));
    font-weight: 600;
    font-family: 'Raleway';
    -webkit-text-stroke: 4px #35344140;
    animation: marquee 10s linear infinite;
    display: inline-block;
    white-space: nowrap;
    padding: 0;
}

@keyframes marquee {
    0% {
        transform: translateX(120%);
    }

    100% {
        transform: translateX(-100%);
    }
}