.parent {
    padding: 1% 4%;
    background-color: transparent;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: calc(12px + (16 - 12) * ((100vw - 380px) / (1200 - 380)));
}

.logo {
    width: 20%;
    position: absolute;
    left: 0;

    @media screen and (max-width:1024px) {
        width: 20%;
    }

    @media screen and (max-width:480px) {
        position: relative;
        width: 64%;
        margin: 4% 0;
    }
}

.navLinksContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width:480px) {
        display: none;
    }
}

.navLinks {
    margin: 0 24px;
    font-size: 1em;
    color: #353441;
    font-weight: 600;
    padding: 24px 0;
    text-decoration: none;
    font-family: 'Raleway';
}

.navLinks:hover {
    color: #558E68;
}

.contact {
    background-color: #558E68;
    border-radius: 20px 6px 20px 6px;
    width: max-content;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 12px 32px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1em;
    box-shadow: 0 0 24px #558E6864;
    position: absolute;
    right: 0;

    @media screen and (max-width:880px) {
        padding: 12px 24px;
    }

    @media screen and (max-width:480px) {
        padding: 12px 18px;
        display: none;
    }
}


.sidebarIcon {
    font-size: 2em;
    color: #558E68;
    margin-right: 4px;
}