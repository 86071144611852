.parent {
    padding: 2% 8% 4% 8%;

    @media screen and (max-width:480px) {
        padding: 8% 4%;
    }
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #558E68;
    border-radius: 24px;
    padding: 56px 53px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width:768px) {
        padding: 32px 24px;

    }

    @media screen and (max-width:480px) {
        flex-direction: column;
        padding: 24px 24px;
    }
}

.wheatSackImage {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 22%;

    @media screen and (max-width:540px) {
        width: 48%;
    }
}

.countour {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 0;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    font-size: calc(32px + (46 - 32) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width:540px) {
        padding-bottom: 120px;
    }
}

.contentHeader {
    display: flex;
    flex-direction: column;
}

.headerTitle {
    font-size: 1em;
    font-weight: 600;
    color: #FFFFFF;
    font-family: 'Raleway';

    @media screen and (max-width:540px) {
        line-height: 140%;
    }
}

.headerSubtitle {
    font-size: 0.6em;
    margin-bottom: 40px;
    color: #FFFFFF;
    font-weight: 200;

    @media screen and (max-width:540px) {
        line-height: 140%;
    }
}

.ctaButton {
    text-decoration: none;
    ;
    background-color: #E96035;
    border-radius: 20px 6px 20px 6px;
    width: max-content;
    font-size: 0.45em;
    display: flex;
    align-items: center;
    padding: 16px 28px;
    color: #FFFFFF;
    box-shadow: 0 0 24px #00000024;

    @media screen and (max-width:480px) {
        padding: 12px 18px;
    }
}

.ctaButtonIcon {
    background-color: #FFFFFF;
    color: #E96035;
    border-radius: 1000px;
    margin-left: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    aspect-ratio: 1;
    transition: 0.5s ease;
}

.ctaButton:hover {
    .ctaButtonIcon {
        transform: rotate(-45deg);
        transition: 0.5s ease;
    }
}