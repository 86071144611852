.sidebarRoot {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    z-index: 999;
    width: 88%;
    right: 0;
    justify-content: space-between;
    box-shadow: 0px 8px 16px #00000072;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 32px 0 0 32px;
    padding: 24px 20px;
}

.sidebarHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.closeIcon {
    font-size: 32px;
    color: #558E68;
}

.logo {
    width: 72%;
}

.linkContainer {
    background-color: #FFFFFF;
    border-radius: 24px 6px 24px 6px;
    padding: 24px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    box-shadow: 0 0 16px #00000024;
}

.link {
    text-decoration: none;
    color: #558E68;
    width: 100%;
    padding: 8px 12px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 22px;
    background-color: var(--backgroundColor);
}

.contactButton {
    background-color: #558E68;
    border-radius: 24px 24px;
    width: max-content;
    display: flex;
    font-size: 1em;
    align-items: center;
    color: #FFFFFF;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 0 24px #558E68AA;
    padding: 12px 18px;
    text-decoration: none;
}


.contactButtonIcon {
    background-color: #FFFFFF;
    color: #558E68;
    border-radius: 1000px;
    margin-left: 20px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    transition: 0.5s ease
}

.contactButton:hover {
    .contactButtonIcon {
        transform: rotate(-45deg);
        transition: 0.5s ease
    }
}