.root {
    display: flex;
    flex-direction: column;
}

.parent {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: space-between;
    color: #353441;
    background-image: linear-gradient(360deg, #FFFFFF80 20%, #FFFFFF00);
}

.logoContainer {
    width: 72%;

    @media screen and (max-width: 1180px) {
        width: 56%;
    }

    @media screen and (max-width: 480px) {
        width: 96%;
    }
}

.content {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    padding: 4%;
    justify-content: space-between;
    color: #353441;

    @media screen and (max-width: 1180px) {
        flex: 0.9;
    }

    @media screen and (max-width: 880px) {
        flex-direction: column;
        flex: 1;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
    }
}

.image {
    flex: 0.2;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 880px) {
        display: none;
    }
}

.footerProp {
    width: 100%;
}

.social {
    display: flex;
    flex-direction: column;
    margin-right: 72px;
    font-size: calc(16px + (16 - 16) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 480px) {
        margin-top: 24px;
    }
}

.email {
    margin: 16px 0;
    font-size: 1.1em;
    text-decoration: none;
    color: #353441;

    @media screen and (max-width: 480px) {
        padding: 0 12px;
    }
}

.socialContainer {
    display: flex;
    flex-direction: row;
    font-size: 1.4em;

    @media screen and (max-width: 480px) {
        padding: 0 12px 24px 12px;
    }
}

.socialIcon {
    margin-right: 20px;
    text-decoration: none;
    color: #353441
}

.socialIcon:hover {
    color: var(--hover-color)
}

.linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: max-content;
    flex: 1;

    @media screen and (max-width: 880px) {
        margin-top: 32px;
        width: 80%;
    }

    @media screen and (max-width: 480px) {
        margin-top: 12px;
        flex-direction: column;
        padding: 0 12px;
    }
}

.links {
    display: flex;
    flex-direction: column;
    margin-right: 72px;
    width: max-content;
    align-items: flex-start;
    font-size: calc(20px + (22 - 20) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 480px) {
        margin-top: 24px;
        line-height: 100%;
    }
}

.linksHeader {
    font-size: 0.9em;
    margin-bottom: 12px;
    font-weight: 700;
    font-family: 'Raleway';
    width: max-content;
}

.linksTextContainer {
    display: flex;
    flex-direction: column;
}

.linkText {
    font-size: 0.8em;
    padding-bottom: 16px;
    text-decoration: none;
    color: #353441;
}

.linkText:hover {
    color: #558E68;
}

.copyrightParent {
    display: row;
    background-color: #FBE385;
    display: grid;
    grid-template-columns: repeat(var(--grid-size), 1fr);
    padding: 8px 16px;
    font-size: calc(16px + (16 - 16) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 8px 24px;
    }
}

.createdBy {
    color: #353441;
    text-decoration: none;
}

.copyrightText {
    color: #353441;
    width: 100%;
    display: flex;
    justify-content: var(--alignment);

    @media screen and (max-width: 768px) {
        margin-top: 4px;
        justify-content: flex-start;

    }
}