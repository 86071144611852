.parent {
    padding: 0 4%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #353441;
}

.listCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    padding: 28px 0;
    border-radius: 12px;
    box-shadow: 0 0 16px #00000012;
    padding: 24px;

    @media screen and (max-width: 840px) {
        flex-direction: column;
    }
}

.listCardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    font-size: calc(26px + (28 - 26) * ((100vw - 380px) / (1200 - 380)));
}

.listCardTitle {
    font-size: 1em;
    margin-bottom: 4px;
    font-weight: 600;
}

.listCardDescription {
    font-size: 0.55em;
    margin-bottom: 20px;
}

.listCardPillContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.listCardPill {
    color: #FFFFFF;
    padding: 4px 18px;
    font-size: 0.5em;
    font-weight: 600;
    border-radius: 32px;
    border: 2px solid #F3BC4C;
    color: #353441;
    margin-right: 12px;
}

.listCardApplyContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @media screen and (max-width: 840px) {
        margin-top: 24px;
        align-self: flex-end;
    }
}

.listCardApplyButton {
    font-size: calc(22px + (24 - 22) * ((100vw - 380px) / (1200 - 380)));
    font-weight: 500;
    color: #558E68;
    font-weight: 600;
    text-decoration: none;

    @media screen and (max-width: 840px) {
        margin-left: 8px;
    }
}

.notHiring{
    font-size: calc(20px + (22 - 20) * ((100vw - 380px) / (1200 - 380)));
    text-align: center;
    color: #353441;
    font-weight: 600;
    padding: 4% 0;
}