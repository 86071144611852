.parent {
    padding: 0 4%;
}

.container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0 4% 0 0;
}

.formHeader {
    font-size: calc(32px + (36 - 32) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 768px) {
        padding: 0;
        padding-left: 2%;
    }

    @media screen and (max-width: 488px) {
        padding: 0;
        padding-left: 4%;
    }
}

.formTitle {
    font-size: 1em;
    margin-bottom: 12px;
    color: #353441;
    font-family: 'Raleway';
    font-weight: 600;
}

.formDescription {
    font-size: 0.5em;
    color: #353441;

    @media screen and (max-width: 768px) {
        margin-bottom: 0px;
    }
}

.contactImageContainer {
    flex: 1;
    border-radius: 28px;
    overflow: hidden;
    height: min-content;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;

    @media screen and (max-width:480px) {
        margin-bottom: 12%;
    }
}

@keyframes shimmer {
    to {
        background-position-x: 0%
    }
}

.contactImage {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.contactButton {
    background-color: #E96035;
    border-radius: 20px 6px 20px 6px;
    width: max-content;
    display: flex;
    font-size: 1em;
    align-items: center;
    padding: 16px 28px;
    color: #FFFFFF;
    margin-top: 24px;

    @media screen and (max-width:880px) {
        padding: 14px 24px;
        font-size: 1em;
    }

    @media screen and (max-width:480px) {
        padding: 12px 18px;
        font-size: 0.9em;
    }
}

.contactButtonIcon {
    background-color: #FFFFFF;
    color: #E96035;
    border-radius: 1000px;
    margin-left: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    aspect-ratio: 1;
    transition: 0.5s ease;
}

.contactButton:hover {
    .contactButtonIcon {
        transform: rotate(-45deg);
        transition: 0.5s ease;
    }
}