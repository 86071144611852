.parent {
    background-color: transparent;
    padding: 12% 0 4% 0;

    @media screen and (max-width:480px) {
        padding: 12% 0 8% 0;
    }
}

.container {
    position: relative;
    background-color: #FFDC74;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #353441;

    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        overflow: hidden;
        padding: 0 4%;
    }
}

.figuresContainer {
    background-color: #FFDC74;
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 64%;
    border-radius: 28px;
    margin-right: 2%;
    align-self: flex-end;
    column-gap: 2px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        width: 84%;
        margin-right: 0;
        row-gap: 2px;
        grid-template-columns: repeat(2, 1fr);

    }
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    /* background-color: red; */
    z-index: 2;

    @media screen and (max-width: 880px) {
        left: -80px;
    }

    @media screen and (max-width: 480px) {
        /* position: relative; */
        bottom: -32px;
        left: -160px;
    }
}

.image {
    aspect-ratio: 1;
    max-width: 72%;

    @media screen and (max-width: 768px) {
        max-width: 64%;
    }

    @media screen and (max-width: 480px) {
        max-width: 56%;
    }

}

.figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFFcd;
    padding: 24px 0;
    font-size: calc(22px + (24 - 22) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width:480px) {
        padding: 24px 0;

    }
}

.figureIcon {
    font-size: 1em;
    color: #558E68;
}

.figureTitle {
    font-size: 1.1em;
    margin-top: 12px;
    font-weight: 700;
}

.figureSubtitle {
    font-size: 0.6em;
}