.parent {
    padding: 0% 4%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(32px + (40 - 32) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width: 840px) {
        align-items: center;
    }

}

.headerAnimation {
    width: 10%;

    @media screen and (max-width: 840px) {
        width: 20%;
    }

    @media screen and (max-width: 540px) {
        width: 40%;
    }
}

.headerTitle {
    font-size: 1em;
    margin: 12px 0;
    color: #353441;
    font-family: 'Raleway';
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 540px) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.headerBadge {
    color: #E96035;
    font-size: 0.35em;
    margin-left: 16px;
    background-color: #FCEFEA;
    border-radius: 32px;
    padding: 2px 12px;
    margin-top: 8px;
    border: 1px solid #E96035;
    
    @media screen and (max-width: 540px) {
        font-size: 0.45em;
        margin-left: 0px;
        margin-bottom: 4px;
    }
}

.headerDescription {
    font-size: 0.5em;
    color: #353441;
    text-align: center;
}