.parent {
    padding: 3% 4%;
    background-color: #FCEBA5;
    color: #353441;

    @media screen and (max-width:480px) {
        padding: 8% 8%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width:480px) {
        flex-direction: column;
    }
}

.catgory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 40px;
    font-size: calc(20px + (22 - 20) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width:480px) {
        margin-right: 0px;
        margin-bottom: 24px;
    }
}

.catgoryTitle {
    font-size: 0.9em;
    margin-bottom: 12px;
    color: #558E68;
    font-family: 'Raleway';
    font-weight: 700;
}

.catgoryInfoAddress {
    font-size: 0.7em;
    margin-bottom: 8px;
    color: #353441;
    font-family: 'Nunito';
    font-weight: 500;
}

.catgoryInfoPhone {
    font-size: 0.7em;
    color: #353441;
    font-family: 'Nunito';
    font-weight: 500;
}

.catgoryInfoSplitter {
    width: 24%;
    height: 1px;
    background-color: #e4e4e4;
    margin: 12px 0;
}