.parent {
    padding: 1% 2%;
}

.heroBackground {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
    color: #353441;
}

.heroImage {
    position: absolute;
    z-index: 0;
    width: 100%;

    @media screen and (max-width:480px) {
        display: none;
    }

}

.contentBackground {
    display: flex;
    width: 100%;
    flex-direction: row;
    z-index: 2;

    @media screen and (max-width:480px) {
        flex-direction: column;
        align-items: center;
        background-color: #f7c851;
        padding-bottom: 8%;
        border-radius: 28px;
    }
}

.contentDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width:480px) {
        height: min-content;
        max-width: 80%;
        flex-basis: 100%;
    }
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 88%;
    aspect-ratio: 1;
    font-size: calc(42px + (60 - 42) * ((100vw - 380px) / (1200 - 380)));

    @media screen and (max-width:480px) {
        justify-content: flex-start;
        height: max-content;
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

.heroHeader {
    font-size: 1em;
    font-weight: 600;
    font-family: 'Raleway';

    @media screen and (max-width:880px) {
        font-size: 0.7em;
    }

    @media screen and (max-width:480px) {
        font-size: 1em;
        line-height: 120%;
    }
}

.heroDescription {
    font-size: 0.34em;
    margin-top: 12px;
    margin-bottom: 48px;

    @media screen and (max-width:880px) {
        margin-bottom: 24px;
        font-size: 0.32em;
    }

    @media screen and (max-width:480px) {
        font-size: 0.45em;
    }
}

.heroButton {
    background-color: #E96035;
    border-radius: 20px 6px 20px 6px;
    width: max-content;
    display: flex;
    font-size: 0.3em;
    align-items: center;
    padding: 16px 28px;
    color: #FFFFFF;
    text-decoration: none;
    box-shadow: 0 0 24px #E9603564;

    @media screen and (max-width:880px) {
        padding: 14px 24px;
        font-size: 0.3em;
    }

    @media screen and (max-width:480px) {
        padding: 12px 18px;
        font-size: 0.4em;
    }
}

.heroButtonIcon {
    background-color: #FFFFFF;
    color: #E96035;
    border-radius: 1000px;
    margin-left: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    aspect-ratio: 1;
    transition: 0.5s ease
}

.heroButton:hover {
    .heroButtonIcon {
        transform: rotate(-45deg);
        transition: 0.5s ease
    }
}

.contentVideoContainer {
    max-width: 50%;
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:480px) {
        height: min-content;
        max-width: 88%;
        flex-basis: 70%;
    }
}

.contentVideoBox {
    width: 88%;
    aspect-ratio: 1;
    border-radius: 28px;
    box-shadow: 0 0 16px #00000024;
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;

    @media screen and (max-width:480px) {
        width: 100%;

    }
}

@keyframes shimmer {
    to {
        background-position-x: 0%
    }
}